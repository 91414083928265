<template>
  <div>
    <v-row>
      <v-col cols="9">
        <h2>Liste des propositions</h2>
      </v-col>
      <v-col v-if="false" cols="3">
        <v-btn color="primary">
          <v-icon class="mr-1">
            mdi-plus-circle
          </v-icon>
          Ajouter
        </v-btn>
      </v-col>
    </v-row>
    <data-table
      :fields="columns"
      api-url="/propositions"
      pagination-path="meta"
      :search="false"
    />
  </div>
</template>

<script>
import DataTable from '../../components/datatable/table.vue';

export default {
  name: 'List',
  components: { DataTable },
  data () {
    return {
      columns: [
        {
          name: 'tiers',
          title: 'Société',
        },
        {
          name: 'products',
          title: 'Produits',
        },
        {
          name: 'marges',
          title: 'Marges',
        },
        {
          name: 'primes',
          title: 'Primes',
        },
        {
          name: 'status',
          title: 'Statut',
        },
        {
          name: 'commercial',
          title: 'Commercial',
        },
      ],
    };
  },
};
</script>

<style>
</style>
