<template>
    <span v-html="str"/>
</template>

<script>
  export default {
    name: "Safe",
    props: {
      str: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style>

</style>
