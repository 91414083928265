import {
  ValidationObserver, ValidationProvider, extend, setInteractionMode, localize,
} from 'vee-validate';
import fr from 'vee-validate/dist/locale/fr.json';

import {
  required, email, min, confirmed,
} from 'vee-validate/dist/rules';

export default {
  install: (Vue) => {
    setInteractionMode('eager');
    localize('fr', fr);
    // Add the required rule
    extend('required', required);
    extend('email', email);
    extend('min', min);
    extend('confirmed', confirmed);
    Vue.component('ValidationObserver', ValidationObserver);
    Vue.component('ValidationProvider', ValidationProvider);
  },
};
