import axios from 'axios';
import LoginVue from '../app/components/login.vue';
import {
  fromKey, isEmpty
} from './functions';

/**
 * class login
 *
 */
class ReLogin {
  constructor () {
    this.app = false;
    this.http = false;
    this.opened = false;
    this.store = false;
    this.request = [];
  }

  setApp (app) {
    this.app = app;
    return this;
  }

  setStore (store) {
    this.store = store;
  }

  setHttp (http) {
    this.http = http;
  }

  processRequest () {
    this.request.forEach(async (request) => {
      try {
        const response = await this.http(request.response.config);
        request.resolve(response);
      } catch (err) {
        request.reject(request.response);
      }
    });
    this.request = [];
  }

  login (response) {
    if (!this.app || !this.http) {
      throw new Error('not initialized');
    }
    if (isEmpty(this.store.state.user)) {
      console.info('not already loggued'); //eslint-disable-line
      return response;
    }
    if (this.opened) {
      return new Promise((resolve, reject) => {
        this.request.push({
          resolve,
          reject,
          response,
        });
      });
    }
    this.opened = true;
    return new Promise((resolve) => {
      this.app.$modal.show(LoginVue, {
        resolve,
        response,
        closed: () => {
          this.opened = false;
          this.processRequest();
        },
      }, { clickToClose: false });
    });
  }
}

const displayErrors = (errors, app) => {
  if (typeof errors === 'object') {
    Object.keys(errors).forEach(key => {
      if (Array.isArray(errors[key])) {
        errors[key].forEach(error => {
          app.$notify.error(error);
        });
      }
    });
    return;
  }
  app.$notify.error(errors);
};

export default {
  install: (Vue) => {
    axios.Axios.prototype.login = new ReLogin();
    const http = axios.create({
      baseURL: '/admin/',
      validateStatus(status) {
        return status >= 200 && status <= 401;
      }
    });
    http.interceptors.request.use(function (config) {
      config.validateStatus = function (status) {
        return status >= 200 && status <= 401;
      }
      return config;
    });
    http.interceptors.response.use(async (response) => {
      if (response.status === 401) {
        try {
          response = await http.login.login(response);
        } catch (error) {
          console.log(error); //eslint-disable-line
        }
      }
      return response;
    }, (error) => {
      if (error.response) {
        const status = error.response.status;
        if (status === 500) {
          http.login.app.$notify.error('une erreur est survenue...');
        }
        if (status === 422) {
          displayErrors(fromKey(error, 'response.data.errors'), http.login.app);
        }
      }
      return Promise.reject(error);
    });
    http.login.setHttp(http);
    Vue.prototype.$http = http;
  },
};
