import Vue from 'vue'; // eslint-disable-line
import { ActionContext } from 'vuex'; // eslint-disable-line

const TMS = 2 * MINUTEJS;

const Dict = {
  namespaced: true,
  state: {
    catTms: 0,
    categories: [],
    marqueTms: 0,
    marques: [],
  },
  actions: {
    /**
     *
     * @param {ActionContext} context
     * @param {Vue} inst
     */
    getCategories (context, inst) {
      const now = new Date().getTime();
      if (context.state.catTms > now - TMS) {
        return;
      }
      inst.$http.get('/categories/dict').then(({ data }) => {
        context.commit('setCategories', data);
        context.commit('setCatTms', new Date().getTime());
      }).catch(err => console.error(err));
    },
    /**
     *
     * @param {ActionContext} context
     * @param {Vue} inst
     */
    getMarques (context, inst) {
      const now = new Date().getTime();
      if (context.state.marqueTms > now - TMS) {
        return;
      }
      inst.$http.get('/brands/dict').then(({ data }) => {
        context.commit('setMarques', data);
        context.commit('setMarqueTms', new Date().getTime());
      }).catch(err => console.error(err));
    },
  },
  mutations: {
    setCategories (state, categories) {
      state.categories = categories;
    },
    setCatTms (state, tms) {
      state.catTms = tms;
    },
    setMarques (state, categories) {
      state.marques = categories;
    },
    setMarqueTms (state, tms) {
      state.marqueTms = tms;
    },
  },
};

export default Dict;
