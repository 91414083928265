<template>
  <div>
    <v-row>
      <v-col>
        <h2>Liste des marques</h2>
      </v-col>
      <v-col cols="auto">
        <v-btn color="primary" @click="create">
          <v-icon class="mr-1">
            mdi-plus-circle
          </v-icon>
          Ajouter
        </v-btn>
      </v-col>
    </v-row>
    <data-table
      ref="vue-table"
      api-url="/brands"
      :fields="columns"
      :show-sort-icons="true"
      :sort-order="sortOrder"
    >
      <div slot="actions" slot-scope="props">
        <div class="custom-actions">
          <v-btn
            color="primary"
            @click="view(props.rowData)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn color="danger" @click="doDelete(props.rowData)">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </div>
      </div>
    </data-table>
    <v-navigation-drawer
      v-model="drawer"
      width="400px"
      right
      temporary
      absolute
    >
      <marque :marque="current" @update="updateMarque"/>
    </v-navigation-drawer>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="320">
        <v-card>
          <v-card-title>
            Suppression de la marque
          </v-card-title>
          <v-card-text>Etes vous sur de supprimer cette marque ?</v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="darken-1" text @click="dialog = false">
              non
            </v-btn>
            <v-btn color="red darken-1" text @click="confirmDelete">
              oui
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import DataTable from '../../components/datatable/table.vue';
import Marque from '../Drawers/Marque.vue';

export default {
  name: 'Marques',
  components: {
    Marque,
    DataTable,
  },
  data: function () {
    return {
      columns: [
        {
          name: 'title',
          title: 'Marque',
          sortField: 'title',
          titleClass: 'col-5 center aligned',
          dataClass: ' aligned',
        },
        {
          name: '__slot:actions',
          title: '',
          titleClass: 'col-2 center aligned',
          dataClass: ' ',
        },
      ],
      sortOrder: [
        {
          field: 'title',
          direction: 'asc',
        },
      ],
      current: null,
      drawer: false,
      dialog: false,
    };
  },
  methods: {
    view (marque) {
      this.current = { ...marque };
      this.drawer = true;
    },
    updateMarque () {
      this.drawer = false;
      this.current = {};
      this.$events.$emit('refresh');
    },
    create () {
      this.current = { title: '' };
      this.drawer = true;
    },
    doDelete (marque) {
      this.dialog = true;
      this.toDelete = marque;
    },
    confirmDelete () {
      if (this.$empty(this.toDelete)) {
        this.dialog = false;
        return;
      }
      this.$http.delete(`/brands/${this.toDelete.id}`).then(() => {
        this.dialog = false;
        this.$events.$emit('refresh');
      }).catch(err => console.error(err));
    },
  },
};
</script>

<style>

</style>
