<template>
  <div>
    <div v-if="!edit">
      <v-card v-if="!$empty(product)" elevation="2">
        <v-row>
          <v-col>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  {{ product.modele }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ product.marque.title }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="!$empty(product.category)" class="subtitle-2 mt-1">
                  {{ product.category.title }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col class="mr-2" cols="auto">
            <v-btn color="primary" @click="startEdit">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-text class="text--primary">
          <v-row>
            <v-col cols="8">
              <div class="mb-2">
                Prix: {{ $price(product.price) }}
              </div>
              <div class="mb-2">
                Prime: {{ $price(product.prime) }}
              </div>
              <div class="mb-2">
                Rapidité: {{ product.rapidite }}
              </div>
              <h5>Description</h5>
              <safe :str="product.description | br | safe" />
              <div v-if="!$empty(product.doc)" class="mt-3 col-6">
                <span class="font-weight-bold">Documentation:</span>
                <a :href="pdfUrl" target="_blank" rel="noopener">{{ product.doc.FileName }}</a>
              </div>
              <file-pond
                ref="pdfPond"
                :allow-multiple="false"
                :label-idle="hasDoc ? 'Changer la documentation' : 'Ajouter une documentation'"
                label-file-loading="Chargement"
                label-file-processing="transfert"
                label-tap-to-cancel="Clique pour annuler"
                :server="pdfServer"
                @processfile="processPdf"
              />
            </v-col>
            <v-col cols="4">
              <v-img
                v-if="hasImage"
                :src="product.photo"
                max-width="200"
                max-height="250"
                contain
              />
              <file-pond
                ref="pond"
                :allow-multiple="false"
                :label-idle="hasImage ? 'Changer la photo' : 'Ajouter une photo'"
                label-file-loading="Chargement"
                label-file-processing="transfert"
                label-tap-to-cancel="Clique pour annuler"
                :server="photoServer"
                @processfile="process"
              />
              <div>
                <v-row>
                  <v-col>
                    <h3>Options</h3>
                  </v-col>
                  <v-col>
                    <v-btn color="primary" x-small @click="drawer = true">
                      <v-icon x-small>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <ul v-if="product.options.length > 0">
                  <li v-for="option in product.options" :key="option.id">
                    {{ option.name }}: {{ $price(option.price) }}
                    <v-btn x-small color="primary" @click="doEdit(option)">
                      <v-icon x-small>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                    <v-btn x-small color="danger" @click="doDelete(option)">
                      <v-icon x-small>
                        mdi-trash-can
                      </v-icon>
                    </v-btn>
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <div v-else>
        <v-overlay opacity="1">
          <v-progress-circular color="primary" indeterminate size="64" />
        </v-overlay>
      </div>
      <v-navigation-drawer
        v-model="drawer"
        width="400px"
        right
        temporary
        absolute
      >
        <div class="pa-4">
          <v-text-field v-model="current.name" label="Nom" />
          <v-text-field v-model="current.price" label="Prix" />
          <v-btn
            v-if="!$empty(current.id)"
            class="float-right"
            color="primary"
            @click="update"
          >
            Enregistrer
          </v-btn>
          <v-btn
            v-else
            color="primary"
            class="float-right"
            @click="create"
          >
            Créer
          </v-btn>
        </div>
      </v-navigation-drawer>
      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="320">
          <v-card>
            <v-card-title>
              Suppression de l'option
            </v-card-title>
            <v-card-text>Êtes vous sur de supprimer l'option {{ toDelete.name }} ?</v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="darken-1" text @click="dialog = false">
                non
              </v-btn>
              <v-btn color="red darken-1" text @click="confirmDelete">
                oui
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <v-card v-else class="pa-3">
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <ValidationProvider
            v-slot="{ errors }"
            name="modèle"
            vid="editProduct.modele"
            rules="required"
          >
            <v-text-field
              v-model="editProduct.modele"
              :error-messages="errors"
              label="Modèle"
              required
            />
          </ValidationProvider>
          <v-select
            v-model="editProduct.marque_id"
            :items="marques"
            item-value="id"
            item-text="title"
            label="Marque"
          />
          <v-select
            v-model="editProduct.category_id"
            :items="categories"
            item-text="title"
            item-value="id"
            label="Catégorie"
          />
          <v-text-field v-model="editProduct.price" label="Prix" />
          <v-text-field v-model="editProduct.prime" label="Prime" />
          <v-textarea v-model="editProduct.description" label="Description" auto-grow />

          <div class="float-right">
            <v-btn class="orange" @click="edit = false">
              Annuler
            </v-btn>
            <v-btn :disabled="invalid" class="primary" @click="submit">
              Enregistrer
            </v-btn>
          </div>
        </form>
      </validation-observer>
    </v-card>
  </div>
</template>

<script>
import {
  CancelToken,
} from 'axios';
import {
  FilePond,
} from '../../plugins/filepond';
import {
  mapActions, mapState,
} from 'vuex';

/**
 * @typedef Product
 * @property {string} modele
 * @property {Object} doc
 * @property {number} price
 * @property {number} rapidite
 * @property {number} prime
 * @property {string} photo
 * @property {array} options
 */

/**
 *
 */
export default {
  name: 'Card',
  components: { FilePond },
  props: {
    id: {
      type: Number,
      required: true,
    },
    row: {
      default: () => ({}),
      type: Object,
    },
  },
  data () {
    return {
      /**
       * @type Product
       */
      product: {},
      /**
       * @var Product
       */
      editProduct: {},
      drawer: false,
      current: {},
      toDelete: {},
      dialog: false,
      edit: false,
      pdfServer: {
        process: this.uploadPdf,
        fetch: null,
        revert: null,
        load: null,
        restore: null,
        patch: null,
      },
      photoServer: {
        process: this.upload,
        fetch: null,
        revert: null,
        load: null,
        restore: null,
        patch: null,
      },
    };
  },
  computed: {
    ...mapState({
      marques: state => state.dict.marques,
      categories: state => state.dict.categories,
    }),
    hasImage () {
      return !this.$empty(this.product.photo);
    },
    pdfUrl () {
      return `/admin/products/${this.id}/doc`;
    },
    hasDoc () {
      return !this.$empty(this.product.doc);
    },
  },
  watch: {
    row (value) {
      this.product = value;
    },
  },
  mounted () {
    if (this.$empty(this.product)) {
      this.product = this.row;
    }
    this.fetch();
    this.getCategories(this);
    this.getMarques(this);
  },
  methods: {
    ...mapActions('dict', ['getMarques', 'getCategories']),
    fetch () {
      this.$http.get(`/products/${this.id}`).then(({ data }) => {
        this.product = data;
      }).catch(err => console.error(err));
    },
    process () {
      this.$refs.pond.removeFiles();
      this.fetch();
    },
    upload (fieldName, file, metadata, load, error, progress, abort) {
      const formData = new FormData();
      const cancelToken = CancelToken.source();
      formData.append('photo', file, file.name);
      this.$http.post(`/products/${this.id}/photo`, formData, {
        onUploadProgress: (e) => progress(e.lengthComputable, e.loaded, e.total),
        cancelToken: cancelToken.token,
      }).then(({ data }) => {
        load(data);
      }).catch(err => error(err));
      return {
        abort: () => {
          cancelToken.cancel();
          abort();
        },
      };
    },
    submit () {
      const data = {
        modele: this.editProduct.modele,
        marque_id: this.editProduct.marque_id,
        price: this.editProduct.price,
        prime: this.editProduct.prime,
        description: this.editProduct.description,
        category_id: this.editProduct.category_id,
      };
      this.$http.put(`/products/${this.id}`, data).then(({ data }) => {
        this.editProduct = {};
        this.edit = false;
        this.product = data;
      }).catch(err => console.error(err));
    },
    startEdit () {
      this.editProduct = { ...this.product };
      this.edit = true;
    },
    uploadPdf (fieldName, file, metadata, load, error, progress, abort) {
      const formData = new FormData();
      const cancelToken = CancelToken.source();
      formData.append('doc', file, file.name);
      this.$http.post(`/products/${this.id}/doc`, formData, {
        onUploadProgress: (e) => progress(e.lengthComputable, e.loaded, e.total),
        cancelToken: cancelToken.token,
      }).then(({ data }) => {
        load(data);
      }).catch(err => error(err));
      return {
        abort: () => {
          cancelToken.cancel();
          abort();
        },
      };
    },
    processPdf () {
      this.$refs.pdfPond.removeFiles();
      this.fetch();
    },
    confirmDelete () {
      this.$http.delete(`/products/options/${this.toDelete.id}`)
        .then(({ data }) => {
          this.product = data;
          this.toDelete = {};
          this.dialog = false;
        }).catch(err => console.error(err));
    },
    doDelete (option) {
      this.toDelete = option;
      this.dialog = true;
    },
    doEdit (option) {
      this.current = { ...option };
      this.drawer = true;
    },
    update () {
      this.$http.put(`/products/options/${this.current.id}`, {
        name: this.current.name,
        price: this.current.price,
      }).then(({ data }) => {
        this.product = data;
        this.current = {};
        this.drawer = false;
      }).catch(err => console.error(err));
    },
    create () {
      this.$http.post(`/products/options/${this.product.id}`, {
        name: this.current.name,
        price: this.current.price,
      }).then(({ data }) => {
        this.product = data;
        this.current = {};
        this.drawer = false;
      }).catch(err => console.error(err));
    },
  },
};
</script>

<style lang="scss">
.theme--dark {
  .filepond--panel-root {
    background-color: #1E1E1E;
  }

  .filepond--drop-label {
    color: #fff;
  }
}

.theme--light {
  .filepond--panel-root {
    background-color: #fff;
  }
}
</style>
