<template>
    <div>Pipeline en construction</div>
</template>

<script>
  export default {
    name: "Pipeline"
  }
</script>

<style>

</style>
