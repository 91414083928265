import numeral from 'numeral';

export default {
  install: (Vue) => {
    numeral.register('locale', 'fr', {
      delimiters: {
        thousands: ' ',
        decimal: ',',
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
      },
      ordinal: function (number) {
        return number === 1 ? 'er' : 'ème';
      },
      currency: { symbol: '€' },
    });

    // switch between locales
    numeral.locale('fr');
    Vue.prototype.$num = numeral;
    Vue.prototype.$price = (price, format = '0,0.00 $') => numeral(price).format(format);
  },
};
