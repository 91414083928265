<template>
  <div>
    <div v-if="edit">
      <ValidationObserver ref="observer" v-slot="{ invalid, reset }">
        <form>
          <ValidationProvider v-slot="{ errors }" name="Name" rules="required">
            <v-text-field
              v-model="user.name"
              :error-messages="errors"
              label="Name"
              required
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="email" rules="required|email">
            <v-text-field
              v-model="user.email"
              :error-messages="errors"
              label="E-mail"
              required
            ></v-text-field>
          </ValidationProvider>
          <validation-provider v-slot="{errors}" name="mot de passe" vid="password"
                               rules="min:8|confirmed:confirmation">
            <v-text-field v-model="password" messages="Changer le mot de passe" type="password" label="Mot de passe"
                          :error-messages="errors"></v-text-field>
          </validation-provider>
          <validation-provider v-slot="{errors}" name="mot de passe" vid="confirmation" rules="min:8">
            <v-text-field v-model="confirmPassword" type="password" label="Mot de passe"
                          :error-messages="errors"></v-text-field>
          </validation-provider>
          <div class="float-right">
            <v-btn @click="reset" class="warning">Effacer</v-btn>
            <v-btn @click="edit = false" class="orange">Annuler</v-btn>
            <v-btn :disabled="invalid" class="primary" @click="submit">Enregistrer</v-btn>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div v-else>
      <v-row align="end">
        <v-col cols="10"></v-col>
        <v-col cols="2">
          <v-btn @click="edit = true" small color="primary">
            <v-icon>mdi-pencil</v-icon>
            Edition
          </v-btn>
        </v-col>
      </v-row>
      <v-simple-table fixed-header v-if="!$empty(user)">
        <tr>
          <td>Nom</td>
          <td>{{ user.name }}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{{ user.email }}</td>
        </tr>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
  import {mapMutations, mapState} from "vuex";

  export default {
    name: "User",
    data() {
      return {
        user: null,
        edit: false,
        password: '',
        confirmPassword: '',
      }
    },
    watch: {
      storeUser(newVal) {
        if (!this.$empty(newVal)) {
          this.user = {...newVal}
        }
      }
    },
    mounted() {
      this.user = {...this.storeUser}
    },
    computed: {
      ...mapState({storeUser: 'user'}),
    },
    methods: {
      ...mapMutations(['setUser']),
      submit() {
        let user = {
          name: this.user.name,
          email: this.user.email
        };
        if (!this.$empty(this.password)) {
          user.password = this.password;
        }
        this.$http.put('/user', user).then(({data}) => {
          this.setUser(data);
          this.password = '';
          this.confirmPassword = '';
          this.edit = false;
        }).catch(err => console.error(err));
      }
    }
  }
</script>

<style>

</style>
