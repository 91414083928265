import './bootstrap';
import Vue from 'vue';
import vuetify from './app/plugins/vuetify';
import App from './app/App.vue';
import router from './app/router';
import Http from './core/http';
import store from './store';
import Validate from './app/plugins/validate';
import {
  fromKey, isEmpty,
} from './core/functions';
import Debug from './app/components/debug.vue';
import Vmodal from 'vue-js-modal';
import toastr from 'toastr';
import Sanitize from './app/plugins/sanitize';
import numeral from './app/plugins/numeral';
// import '../sass/app.scss';

Vue.use(Http);
Vue.use(Validate);
Vue.use(Sanitize);
Vue.use(numeral);
Vue.use(Vmodal, { dynamic: true });
Vue.prototype.$empty = isEmpty;
Vue.prototype.fromKey = fromKey;
Vue.prototype.$events = new Vue();
Vue.prototype.$notify = toastr;
Vue.component('debug', Debug);
const app = window.app = new Vue({
  el: '#app',
  components: { App },
  vuetify,
  router,
  store,
  data () {
    return { dark: true };
  },
  watch: {
    dark (newVal) {
      this.$vuetify.theme.dark = newVal;
    },
  },
  mounted () {
    this.$vuetify.theme.dark = this.dark;
  },
});
app.$http.login.setApp(app);
app.$http.login.setStore(store);
