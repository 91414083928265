import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

const opts = {
  icons: { iconfont: 'mdi' },
  theme: {
    themes: {
      dark: {
        primary: '#00bcd4',
        secondary: '#2196f3',
        accent: '#3f51b5',
        error: '#f44336',
        danger: '#f44336',
        warning: '#ffc107',
        info: '#03a9f4',
        success: '#8bc34a',
      },
      light: {
        primary: '#00bcd4',
        secondary: '#2196f3',
        accent: '#3f51b5',
        error: '#f44336',
        danger: '#f44336',
        warning: '#ffc107',
        info: '#03a9f4',
        success: '#8bc34a',
      },
    },
  },
};

export default new Vuetify(opts);
