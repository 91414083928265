<template>
  <div class="vue-dialog">
    <div class="dialog-content">
      <div class="dialog-c-title">
        Session expiré
      </div>
      <div class="dialog-c-content">
          <v-text-field v-model="username" label="Email"/>
        <v-text-field v-model="password" type="password" label="Mot de passe" @keypress.enter="connect"/>
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button
        class="vue-dialog-button"
        type="button"
        @click.stop="connect"
      >
        Connexion
      </button>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: 'Login',
  props: {
    resolve: {
      type: Function,
      default: Promise.resolve,
    },
    response: {
      type: Object,
      default: () => ({}),
    },
    opened: {
      type: Function,
      default: () => (''),
    },
    closed: {
      type: Function,
      default: () => (''),
    },
  },
  data () {
    return {
      username: '',
      password: '',
    };
  },
  beforeDestroy () {
    this.closed();
  },
  mounted() {
    if (this.user.email) {
      this.username = this.user.email;
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    connect () {
      this.$emit('close');
      this.$http.post('/login', {email: this.username, password: this.password}).then(async (resp) => {
        if (resp.status === 204) {
          const response = this.response;
          const newResponse = await this.$http(response.config);
          this.resolve(newResponse);
        }
      }).catch((err) => console.error(err));
    },
  },
};
</script>
<style>
.vue-dialog {
  z-index: 10000000;
}
.login_table {
  box-shadow: unset;
  border: unset;
}
.vue-dialog div {
  box-sizing: border-box;
}

.dialog-content {
  flex: 1 0 auto;
  width: 100%;
  padding: 15px;
  font-size: 14px;
}

.dialog-c-title {
  font-weight: 600;
  padding-bottom: 15px;
  text-align: center;
}

.dialog-c-content {
  text-align: center;
}

.vue-dialog-buttons {
  display: flex;
  flex: 0 1 auto;
  width: 100%;
  border-top: 1px solid #eee;
}

.vue-dialog-button {
  font-size: 16px !important;
  background-color: rgb(98, 114, 255);
  color: #fff;
  position: absolute;
  bottom: 0;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 40px;
  height: 40px;
  width: 100%;
  outline: none;

}

.vue-dialog-button:hover {
  background: #5e84ff;
}

</style>
