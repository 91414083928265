<template>
  <div>
    <v-row>
      <v-col>
        <h2>Liste des client</h2>
      </v-col>
      <v-col cols="auto">
        <v-btn color="primary" @click="create">
          <v-icon class="mr-1">
            mdi-plus-circle
          </v-icon>
          Ajouter
        </v-btn>
                <v-btn @click="showTrash">
          <v-icon class="mr-2">
            mdi-trash-can
          </v-icon>
          {{ trashedLabel }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col />
      <v-col cols="auto">

      </v-col>
    </v-row>
    <data-table
      ref="vue-table"
      api-url="/societes"
      :fields="columns"
      :show-sort-icons="true"
      :sort-order="sortOrder"
      :append-params="{ trash }"
    >
      <div slot="actions" slot-scope="props">
        <div class="custom-actions">
          <v-btn
            color="primary"
            :to="{ name: 'ClientCard', params: { id: props.rowData.id, data: props.rowData } }"
            @click="view(props.rowData)"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-btn color="danger" @click.stop="doDelete(props.rowData)">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </div>
      </div>
    </data-table>
    <v-navigation-drawer
      v-model="drawer"
      width="400px"
      right
      temporary
      absolute
      class="pa-3"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <ValidationProvider v-slot="{ errors }" name="Nom" rules="required">
            <v-text-field
              v-model="tiers.name"
              :error-messages="errors"
              label="Nom"
              required
            />
          </ValidationProvider>
          <div class="float-right">
            <v-btn :disabled="invalid" class="primary" @click="submit">
              Enregistrer
            </v-btn>
          </div>
        </form>
      </validation-observer>
    </v-navigation-drawer>
        <v-row justify="center">
      <v-dialog v-model="dialog" max-width="320">
        <v-card>
          <v-card-title>
            Suppression du client
          </v-card-title>
          <v-card-text>Etes vous sur de supprimer ce client ?</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="darken-1" text @click="dialog = false">
              non
            </v-btn>
            <v-btn color="red darken-1" text @click="confirmDelete">
              oui
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import DataTable from '../../components/datatable/table.vue';

export default {
  name: 'List',
  components: { DataTable },
  data () {
    return {
      trash: false,
      columns: [
        {
          name: 'name',
          title: 'Nom',
          sortField: 'name',
        },
        {
          name: 'address',
          title: 'Adresse',
          sortField: 'address',
        },
        {
          name: 'cp',
          title: 'Code postale',
          sortField: 'cp',
        },
        {
          name: 'ville',
          title: 'Ville',
          sortField: 'ville',
        },
        {
          name: '__slot:actions',
          title: '',
          titleClass: ' col-2 center aligned',
          dataClass: 'center aligned',
        },

      ],
      sortOrder: [
        {
          field: 'name',
          direction: 'asc',
        },
      ],
      drawer: false,
      tiers: { name: '' },
      toDelete: null,
      dialog: false,
    };
  },
  computed: {
    trashedLabel () {
      if (this.trash) {
        return 'Sortie de la corbeille';
      }
      return 'Corbeille';
    },
  },
  methods: {
    showTrash () {
      this.trash = !this.trash;
      this.$events.$emit('filter-set', '');
    },
    submit () {
      this.$http.post('/societes', this.tiers).then(({ data }) => {
        this.$events.$emit('filter-set', this.tiers.name);
        this.drawer = false;
      }).catch(err => console.error(err));
    },
    create () {
      this.drawer = true;
    },
    doDelete (tiers) {
      this.dialog = true;
      this.toDelete = tiers;
    },
    confirmDelete () {
      if (this.$empty(this.toDelete)) {
        this.dialog = false;
        return;
      }
      this.$http.delete(`/societes/${this.toDelete.id}`).then(() => {
        this.dialog = false;
        this.$events.$emit('refresh');
      }).catch(err => console.error(err));
    },
  },
};
</script>

<style>
  .full-width {
    width: 100%;
  }
</style>
