<template>
  <div v-if="totalPage > 1">
    <v-pagination
      :total-visible="7"
      :length="totalPage"
      :value="tablePagination.current_page"
      @input="loadPage"
      @next="loadPage('next')"
      @previous="loadPage('prev')"
    />
  </div>
</template>

<script>
import VuetablePaginationMixin from 'vuetable-2/src/components/VuetablePaginationMixin.vue';

export default { mixins: [VuetablePaginationMixin] };
</script>

<style>
  .pagination {
    margin-top: 0;
  }
</style>
