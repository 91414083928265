<template>
  <div>
    <v-row>
      <v-col>
        <h2>Liste des contacts</h2>
      </v-col>
      <v-col cols="auto">
        <v-btn color="primary" @click="drawer = true">
          <v-icon class="mr-1">
            mdi-plus-circle
          </v-icon>
          Ajouter
        </v-btn>
      </v-col>
    </v-row>
    <data-table
      ref="vue-table"
      api-url="/contacts"
      :fields="columns"
      :show-sort-icons="true"
    >
      <div slot="actions" slot-scope="props">
        <div class="custom-actions">
          <v-btn
            color="primary"
            :to="{ name: 'ContactCard', params: { id: props.rowData.id, data: props.rowData } }"
            @click="view(props.rowData)"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-btn color="danger" @click.stop="doDelete(props.rowData)">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </div>
      </div>
    </data-table>
    <v-navigation-drawer
      v-model="drawer"
      width="400px"
      right
      temporary
      absolute
      class="pa-3"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <ValidationProvider v-slot="{ errors }" name="Civilité" rules="required">
            <v-select
              v-model="contact.titre"
              :error-messages="errors"
              label="Civilité"
              :items="civilities"
              item-text="title"
              item-value="value"
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="Nom" rules="required">
            <v-text-field
              v-model="contact.firstname"
              :error-messages="errors"
              label="Nom"
              required
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="Prénom" rules="required">
            <v-text-field
              v-model="contact.lastname"
              :error-messages="errors"
              label="Prénom"
              required
            />
          </ValidationProvider>
          <div class="float-right">
            <v-btn :disabled="invalid" class="primary" @click="submit">
              Enregistrer
            </v-btn>
          </div>
        </form>
      </validation-observer>
    </v-navigation-drawer>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="320">
        <v-card>
          <v-card-title>
            Suppression du contact
          </v-card-title>
          <v-card-text>Etes vous sur de supprimer ce contact ?</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="darken-1" text @click="dialog = false">
              non
            </v-btn>
            <v-btn color="red darken-1" text @click="confirmDelete">
              oui
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import DataTable from '../../components/datatable/table.vue';
import {
  mapState,
} from 'vuex';

export default {
  name: 'List',
  components: { DataTable },
  data: function () {
    return {
      contact:
          {
            firstname: '',
            lastname: '',
            titre: '',
          },
      columns: [
        {
          title: 'Nom',
          name: 'fullname',
          sortField: 'firstname',
          dataClass: 'center aligned',
        },
        {
          title: 'Téléphone',
          name: 'phone',
        }, {
          title: 'Mobile',
          name: 'mobile',
        }, {
          title: 'Mail',
          name: 'mail',
          dataClass: 'center aligned',
        },
        {
          name: '__slot:actions',
          title: '',
          titleClass: 'col-2 center aligned',
          dataClass: 'center aligned',
        },
      ],
      drawer: false,
      dialog: false,
      toDelete: null,
    };
  },
  computed: { ...mapState(['civilities']) },
  methods: {
    submit () {
      this.$http.post('contacts', this.contact).then(({ data }) => {
        this.drawer = false;
        this.$router.push({
          name: 'ContactCard',
          params: {
            id: data.id, data,
          },
        });
      }).catch(err => console.error(err));
    },
    doDelete (contact) {
      this.dialog = true;
      this.toDelete = contact;
    },
    confirmDelete () {
      if (this.$empty(this.toDelete)) {
        this.dialog = false;
        return;
      }
      this.$http.delete(`/contacts/${this.toDelete.id}`).then(() => {
        this.dialog = false;
        this.$events.$emit('refresh');
      }).catch(err => console.error(err));
    },
  },
};
</script>

<style>

</style>
