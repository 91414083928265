<template>
  <div v-if="!$empty(marque)" class="pa-4">
    <v-text-field v-model="marque.title" label="Nom" @keypress.enter="update" />
    <v-btn
      v-if="marque.id"
      class="float-right"
      color="primary"
      @click="update"
    >
      Enregistrer
    </v-btn>
    <v-btn
      v-else
      class="float-right"
      color="primary"
      @click="create"
    >
      Créer
    </v-btn>
  </div>
</template>

<script>

export default {
  name: 'Marque',
  props: {
    marque: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    update () {
      this.$http.put(`/brands/${this.marque.id}`, {
        title: this.marque.title,
        category_id: this.marque.category_id,
      }).then(() => {
        this.$emit('update');
      }).catch(err => console.error(err));
    },
    create () {
      this.$http.post('/brands', {
        title: this.marque.title,
        category_id: this.marque.category_id,
      }).then(() => {
        this.$emit('update');
      }).catch(err => console.error(err));
    },
  },
};
</script>

<style>

</style>
