export const isEmpty = (object) => {
  if (Array.isArray(object)) {
    return object.length === 0;
  }
  return object === null || object === undefined ||
  (typeof object === 'object' && Object.keys(object).length === 0) ||
  object === '';
};


export const fromKey = (object, key, defaut) => {
  const reduceFn = (obj, index) => {
    if (!obj) {
      return defaut;
    }
    return obj[index];
  };
  return key.split('.').reduce(reduceFn, object) || defaut;
};
