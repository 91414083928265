<template>
  <div v-if="!$empty(leaser)" class="pa-4">
    <v-text-field v-model="leaser.title" label="Nom" @keypress.enter="update" />
    <v-btn
      v-if="!$empty(leaser.id)"
      class="float-right"
      color="primary"
      @click="update"
    >
      Enregistrer
    </v-btn>
    <v-btn
      v-else
      color="primary"
      class="float-right"
      @click="create"
    >
      Créer
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'Leaser',
  props: {
    leaser: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    update () {
      this.$http.put(`/leasers/${this.leaser.id}`, { title: this.leaser.title }).then(() => {
        this.$emit('update');
      }).catch(err => console.error(err));
    },
    create () {
      this.$http.post('/leasers', { title: this.leaser.title }).then(() => {
        this.$emit('update');
      }).catch(err => console.error(err));
    },
  },
};
</script>

<style >

</style>
