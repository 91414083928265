<template>
  <div>
    <div v-if="!$empty(leaser)">
      <v-card>
        <v-row>
          <v-col>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  {{ leaser.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <div>
              <div v-if="!$empty(leaser.doc)">
                <span class="font-weight-bold">Contrat:</span>
                <a :href="pdfUrl" target="_blank" rel="noopener">{{ leaser.doc.FileName }}</a>
              </div>
              <file-pond
                ref="pdfPond"
                :allow-multiple="false"
                :label-idle="!$empty(leaser.doc) ? 'Changer le contrat' : 'Ajouter un contrat'"
                label-file-loading="Chargement"
                label-file-processing="transfert"
                label-tap-to-cancel="Clique pour annuler"
                :server="pdfServer"
                @processfile="processPdf"
              />
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="testCoef"
            label="Test du coef"
            :messages="resultCoef"
            @keyup="computeCoef"
          />
        </v-col>
        <v-col />
        <v-col cols="auto">
          <v-btn color="primary" @click="showCreate">
            <v-icon class="mr-1">
              mdi-plus-circle
            </v-icon>
            Ajouter
          </v-btn>
        </v-col>
      </v-row>
      <data-table
        :api-url="`/leasers/${id}/coefs`"
        :fields="columns"
        :search="false"
      >
        <div slot="actions" slot-scope="props">
          <div class="custom-actions">
            <v-btn
              color="primary"
              @click.stop="view(props.rowData)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn color="danger" @click.stop="doDelete(props.rowData)">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </div>
        </div>
      </data-table>
    </div>
    <v-navigation-drawer
      v-model="drawer"
      width="400px"
      right
      temporary
      absolute
    >
      <div class="pa-4">
        <v-text-field v-model="current.amount" label="Montant" />
        <v-text-field v-model="current.coef" label="Coef" />
        <v-btn
          v-if="!$empty(current.id)"
          class="float-right"
          color="primary"
          @click="update"
        >
          Enregistrer
        </v-btn>
        <v-btn
          v-else
          color="primary"
          class="float-right"
          @click="create"
        >
          Créer
        </v-btn>
      </div>
    </v-navigation-drawer>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="320">
        <v-card>
          <v-card-title>
            Suppression du coef
          </v-card-title>
          <v-card-text>Etes vous sur de supprimer ce coef ?</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="darken-1" text @click="dialog = false">
              non
            </v-btn>
            <v-btn color="red darken-1" text @click="confirmDelete">
              oui
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import DataTable from '../../components/datatable/table.vue';
import {
  CancelToken,
} from 'axios';
import {
  FilePond,
} from '../../plugins/filepond';

export default {
  name: 'LeaserCard',
  components: {
    DataTable, FilePond,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    row: {
      default: () => ({}),
      type: Object,
    },
  },
  data: function () {
    return {
      leaser: null,
      columns: [
        {
          title: 'Montant',
          name: 'amount',
          callback: 'price',
        },
        {
          title: 'Coef',
          name: 'coef',
        },
        {
          name: '__slot:actions',
          title: '',
          titleClass: 'col-3 center aligned',
          dataClass: 'center aligned',
        },
      ],
      drawer: false,
      current: {},
      toDelete: null,
      dialog: false,
      testCoef: '',
      resultCoef: '',
      pdfServer: { process: this.uploadPdf },
    };
  },
  computed: {
    pdfUrl () {
      return `/admin/leasers/${this.id}/doc`;
    },
  },
  watch: {
    row (value) {
      this.product = value;
    },
  },
  mounted () {
    if (!this.$empty(this.row)) {
      this.leaser = this.row;
    } else {
      this.fetch();
    }
  },
  methods: {
    fetch () {
      this.$http.get(`/leasers/${this.id}`).then(({ data }) => {
        this.leaser = data;
      }).catch(err => console.error(err));
    },
    doDelete (coef) {
      this.toDelete = coef;
      this.dialog = true;
    },
    view (coef) {
      this.current = { ...coef };
      this.drawer = true;
    },
    update () {
      if (this.$empty(this.current)) {
        return;
      }
      this.$http.put(`/leasers/coefs/${this.current.id}`, this.current).then(({ data }) => {
        this.drawer = false;
        this.leaser = data;
        this.$events.$emit('refresh');
        this.computeCoef();
      }).catch(err => console.error(err));
    },
    showCreate () {
      this.current = {};
      this.drawer = true;
    },
    create () {
      if (this.$empty(this.current)) {
        return;
      }
      this.$http.post(`/leasers/${this.id}/coefs`, this.current).then(({ data }) => {
        this.drawer = false;
        this.$events.$emit('refresh');
        this.leaser = data;
        this.computeCoef();
      }).catch(err => console.error(err));
    },
    confirmDelete () {
      if (this.$empty(this.toDelete)) {
        this.dialog = false;
        return;
      }
      this.$http.delete(`/leasers/coefs/${this.toDelete.id}`).then(({ data }) => {
        this.dialog = false;
        this.$events.$emit('refresh');
        this.leaser = data;
        this.computeCoef();
      }).catch(err => console.error(err));
    },
    computeCoef () {
      if (this.$empty(this.testCoef)) {
        this.resultCoef = '';
      }
      const coef = [...this.leaser.coefs].sort((a, b) => {
        if (a.amount === b.amount) {
          return 0;
        }
        return a.amount > b.amount ? 1 : -1;
      }).find(coef => coef.amount >= parseFloat(this.testCoef));
      if (!coef) {
        this.resultCoef = '';
        return;
      }
      this.resultCoef = `coef: ${coef.coef}`;
    },
    uploadPdf (fieldName, file, metadata, load, error, progress, abort) {
      const formData = new FormData();
      const cancelToken = CancelToken.source();
      formData.append('doc', file, file.name);
      this.$http.post(`/leasers/${this.id}/doc`, formData, {
        onUploadProgress: (e) => progress(e.lengthComputable, e.loaded, e.total),
        cancelToken: cancelToken.token,
      }).then(({ data }) => {
        load(data);
      }).catch(err => error(err));
      return {
        abort: () => {
          cancelToken.cancel();
          abort();
        },
      };
    },
    processPdf () {
      this.$refs.pdfPond.removeFiles();
      this.fetch();
    },
  },

};
</script>

<style scoped>

</style>
