import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './views/Home.vue';
import Router from './components/Router.vue';
import Marques from './views/printers/Marques.vue';
import ClientList from './views/client/List.vue';
import ClientCard from './views/client/Card.vue';
import PrinterList from './views/printers/List.vue';
import PrinterCard from './views/printers/Card.vue';
import CategoryList from './views/dictionaries/Categories.vue';
import StatusList from './views/dictionaries/Status.vue';
import User from './views/user.vue';
import PropositionList from './views/propositions/List.vue';
import PropositionCard from './views/propositions/Card.vue';
import Pipeline from './views/propositions/Pipeline.vue';
import ContactList from './views/contacts/List.vue';
import ContactCard from './views/contacts/Card.vue';
import LeaserList from './views/dictionaries/Leaser.vue';
import LeaserCard from './views/dictionaries/LeaserCard.vue';
import UserList from './views/Users/List';

Vue.use(VueRouter);

const cardProps = (route) => {
  return {
    row: route.params.data,
    id: parseInt(route.params.id, 10),
  };
};

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Home,
      name: 'Home',
    },
    {
      path: '/user',
      component: User,
      name: 'user',
    },
    {
      path: '/users',
      component: Router,
      children: [
        {
          path: '',
          name: 'UserList',
          component: UserList
        },
      ],
    },
    {
      path: '/client',
      component: Router,
      children: [
        {
          path: '',
          name: 'ClientList',
          component: ClientList,
        },
        {
          path: ':id',
          name: 'ClientCard',
          component: ClientCard,
          props: cardProps,
        },
      ],
    },
    {
      path: '/contacts',
      component: Router,
      children: [
        {
          path: '',
          name: 'ContactList',
          component: ContactList,
        },
        {
          path: ':id',
          name: 'ContactCard',
          component: ContactCard,
          props: cardProps,
        },
      ],
    },
    {
      path: '/dict',
      component: Router,
      children: [
        {
          path: 'categories',
          name: 'CategoryList',
          component: CategoryList,
        },
        {
          path: 'leasers',
          component: Router,
          children: [
            {
              path: '',
              name: 'LeaserList',
              component: LeaserList,
            },
            {
              path: ':id',
              name: 'LeaserCard',
              component: LeaserCard,
              props: cardProps,
            },
          ],
        },
        {
          path: 'statuts',
          name: 'StatusList',
          component: StatusList,
        },
        {
          path: 'marques',
          name: 'copieurMarque',
          component: Marques,
        },
        {
          path: 'produits',
          component: Router,
          children: [
            {
              path: '',
              component: PrinterList,
              name: 'PrinterList',
            },
            {
              path: ':id',
              name: 'PrinterCard',
              component: PrinterCard,
              props: cardProps,
            },
          ],
        },
      ],
    },

    {
      path: '/proposition',
      component: Router,
      children: [
        {
          path: '',
          component: PropositionList,
          name: 'PropositionList',
        },
        {
          path: 'pipeline',
          component: Pipeline,
          name: 'Pipeline',
        },
        {
          path: ':id',
          name: 'PropositionCard',
          component: PropositionCard,
          props: cardProps,
        },
      ],
    },
  ],
});
