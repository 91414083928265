var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.edit)?_c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
return [_c('form',[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Name","required":""},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"E-mail","required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"mot de passe","vid":"password","rules":"min:8|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"messages":"Changer le mot de passe","type":"password","label":"Mot de passe","error-messages":errors},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"mot de passe","vid":"confirmation","rules":"min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","label":"Mot de passe","error-messages":errors},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"float-right"},[_c('v-btn',{staticClass:"warning",on:{"click":reset}},[_vm._v("Effacer")]),_vm._v(" "),_c('v-btn',{staticClass:"orange",on:{"click":function($event){_vm.edit = false}}},[_vm._v("Annuler")]),_vm._v(" "),_c('v-btn',{staticClass:"primary",attrs:{"disabled":invalid},on:{"click":_vm.submit}},[_vm._v("Enregistrer")])],1)],1)]}}],null,false,406641577)})],1):_c('div',[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"10"}}),_vm._v(" "),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.edit = true}}},[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v("\n          Edition\n        ")],1)],1)],1),_vm._v(" "),(!_vm.$empty(_vm.user))?_c('v-simple-table',{attrs:{"fixed-header":""}},[_c('tr',[_c('td',[_vm._v("Nom")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.user.name))])]),_vm._v(" "),_c('tr',[_c('td',[_vm._v("Email")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.user.email))])])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }