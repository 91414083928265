<script>
import moment from 'moment';
import Vue from 'vue';
import Vuetable from 'vuetable-2/src/components/Vuetable.vue';
import VuetablePagination from './pagination.vue';
import VuetablePaginationInfo from './VuetablePaginationInfo.vue';
import FilterBar from './FilterBar.vue';

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
  },
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    sortOrder: {
      type: Array,
      default () {
        return [];
      },
    },
    appendParams: {
      type: Object,
      default () {
        return {};
      },
    },
    noDataTemplate: {
      type: String,
      default () {
        return 'Aucun enregistrement';
      },
    },
    detailRowComponent: {
      type: String,
      default: '',
    },
    paginationPath: {
      type: String,
      default: '',
    },
    search: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
  data () {
    return {
      filter: '',
      cssPagination: {
        wrapperClass: 'ui left floated pagination men',
        activeClass: 'active large',
        disabledClass: 'disabled',
        pageClass: 'item',
        linkClass: 'icon item',
        paginationClass: 'ui bottom attached segment grid',
        paginationInfoClass: 'left floated left aligned six wide column',
        dropdownClass: 'ui search dropdown',
        icons: {
          first: 'fa fa-angle-double-left',
          prev: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          last: 'fa fa-angle-double-right',
        },
      },
    };
  },
  mounted () {
    this.$events.$on('filter-set', this.onFilterSet);
    this.$events.$on('refresh', this.refresh);
    this.$events.$on('reload', this.reload);
    this.$events.$on('filter-reset', this.onFilterReset);
  },
  destroyed () {
    this.$events.$off('refresh', this.refresh);
    this.$events.$off('reload', this.reload);
    this.$events.$off('filter-set', this.onFilterSet);
    this.$events.$off('filter-reset', this.onFilterReset);
  },
  methods: {
    //   dP                                       .8888b
    //   88                                       88   "
    // d8888P 88d888b. .d8888b. 88d888b. .d8888b. 88aaa  88d888b. .d8888b. 88d8b.d8b.
    //   88   88'  `88 88'  `88 88'  `88 Y8ooooo. 88     88'  `88 88'  `88 88'`88'`88
    //   88   88       88.  .88 88    88       88 88     88       88.  .88 88  88  88
    //   dP   dP       `88888P8 dP    dP `88888P' dP     dP       `88888P' dP  dP  dP
    //
    //
    allcap (value) {
      return value.toUpperCase();
    },
    userAdmin (value) {
      if (value) {
        return '*';
      }
      return '';
    },
    formatNumber (value) {
      return value;
    },
    price (value) {
      return this.$price(value);
    },
    photo (value) {
      if (!value) {
        return '';
      }
      return `<img src="${value}" class="image-profile"/>`;
    },
    bool (value) {
      return value ? '<i class="fa fa-check-circle text-green"></i>' : '<i class="fa fa-times-circle text-red"></i>';
    },
    key (value, key) {
      return this.fromKey(value, key, '');
    },
    perWeek (value) {
      if (!value) {
        return 'illimité';
      }
      return value;
    },
    durationType (value) {
      switch (value) {
      case 1:
        return 'Mensuel';
      case 2:
        return 'Annuel';
      default:
        return '';
      }
    },
    /**
     * @param {string} value value
     * @returns {string} html icon
     */
    logIcon (value) {
      const icons = {
        DEBUG: 'fa fa-fw fa-bug log-debug',
        INFO: 'fa fa-fw fa-info-circle text-blue log-info',
        NOTICE: 'fa fa-fw fa-file-text log-notice',
        WARNING: 'fa fa-fw fa-warning text-yellow',
        ERROR: 'fa fa-fw fa-times-circle text-red',
        CRITICAL: 'fa fa-fw fa-fire log-critical',
        ALERT: 'fa fa-fw fa-bell log-alert',
        EMERGENCY: 'fa fa-fw fa-flash log-emergency',
      };
      return `<i class="${icons[value]}" title="${value.toLowerCase()}"></i>`;
    },
    formatDate (value, fmt = 'D MMM YYYY') {
      return (value === null)
        ? ''
        : moment(value, 'YYYY-MM-DD HH:mm:ss').format(fmt);
    },
    //                                       dP
    //                                       88
    // .d8888b. dP   .dP .d8888b. 88d888b. d8888P
    // 88ooood8 88   d8' 88ooood8 88'  `88   88
    // 88.  ... 88 .88'  88.  ... 88    88   88
    // `88888P' 8888P'   `88888P' dP    dP   dP
    //
    //
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page);
    },
    onCellClicked (data) {
      this.$refs.vuetable.toggleDetailRow(data.id);
    },
    refresh () {
      this.$refs.vuetable.refresh();
    },
    reload () {
      this.$refs.vuetable.reload();
    },
    onFilterSet (filterText) {
      this.appendParams.filter = filterText;
      Vue.nextTick(() => this.$refs.vuetable && this.$refs.vuetable.refresh());
    },
    onFilterReset () {
      delete this.appendParams.filter;
      this.filter = '';
      Vue.nextTick(() => this.$refs.vuetable && this.$refs.vuetable.refresh());
    },
    //                                  dP
    //                                  88
    // 88d888b. .d8888b. 88d888b. .d888b88 .d8888b. 88d888b.
    // 88'  `88 88ooood8 88'  `88 88'  `88 88ooood8 88'  `88
    // 88       88.  ... 88    88 88.  .88 88.  ... 88
    // dP       `88888P' dP    dP `88888P8 `88888P' dP
    //
    //
    renderFilter (h) {
      if (this.search) {
        return h(FilterBar);
      }
      return null;
    },
    // render related functions
    renderVuetable (h) {
      const theme = this.$vuetify.theme.dark !== true ? 'theme--light' : 'theme--dark';
      return h(
        'vuetable',
        {
          ref: 'vuetable',
          props: {
            css: {
              tableClass: `v-data-table ${theme} full-width`,
              loadingClass: 'loading',
              ascendingIcon: 'blue chevron up icon',
              descendingIcon: 'blue chevron down icon',
              ascendingClass: 'sorted-asc',
              descendingClass: 'sorted-desc',
              sortableIcon: '',
              detailRowClass: 'vuetable-detail-row',
              handleIcon: 'grey sidebar icon',
              tableBodyClass: 'vuetable-semantic-no-top vuetable-fixed-layout',
              tableHeaderClass: 'vuetable-fixed-layout',
            },
            apiUrl: this.apiUrl,
            fields: this.fields,
            paginationPath: this.paginationPath,
            httpFetch: this.$http,
            perPage: this.perPage,
            multiSort: true,
            sortOrder: this.sortOrder,
            appendParams: this.appendParams,
            detailRowComponent: this.detailRowComponent,
            noDataTemplate: this.noDataTemplate,
          },
          on: {
            'vuetable:cell-clicked': this.onCellClicked,
            'vuetable:pagination-data': this.onPaginationData,
          },
          scopedSlots: this.$vnode.data.scopedSlots,
        },
      );
    },
    renderPagination (h) {
      return h(
        'div',
        {
          class: {
            'vuetable-pagination': true, 'col-md-12': true,
          },
        },
        [
          h('vuetable-pagination-info', {
            ref: 'paginationInfo', class: { 'col-md-6': true },
          }),
          h('vuetable-pagination', {
            ref: 'pagination',
            props: { css: this.cssPagination },
            on: { 'vuetable-pagination:change-page': this.onChangePage },
          }),
        ],
      );
    },
  },
  render (h) {
    return h(
      'div',
      {},
      [
        this.renderFilter(h),
        this.renderVuetable(h),
        this.renderPagination(h),
      ],
    );
  },
};
</script>

<style>
.v-data-table.theme--dark  tr:nth-child(even) {
  background: #2c2c2c
}

.v-data-table.theme--dark tr:nth-child(odd) {
  background: #1f1f1f
}

.v-data-table.theme--light  tr:nth-child(even) {
  background: #c2c2c2
}

.v-data-table.theme--light tr:nth-child(odd) {
  background: #dedede
}
.v-data-table td {
  padding: 5px;
}
</style>
