<template>
  <div>
    <v-app v-if="!$empty(user)" id="inspire" :dark="$parent.setTheme">
      <v-navigation-drawer
        v-model="drawer"
        app
        clipped
      >
        <v-list dense>
          <v-list-item link :to="{name: 'Home'}" exact>
            <v-list-item-action>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="{name: 'ClientList'}">
            <v-list-item-action>
              <v-icon>mdi-account-box</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Clients</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            prepend-icon="mdi-briefcase"
            group="proposition"
            :value="proposition"
            active-class="v-list-item--active"
          >
            <template v-slot:activator>
              <v-list-item-title>Proposition</v-list-item-title>
            </template>
            <v-list-item
              link
              :to="{name: 'PropositionList'}"
              exact
              active-class="v-list-item--active"
            >
              <v-list-item-action />
              <v-list-item-content>
                <v-list-item-title>Liste</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              :to="{name: 'Pipeline'}"
            >
              <v-list-item-action />
              <v-list-item-content>
                <v-list-item-title>Pipeline</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group
            prepend-icon="mdi-book-alphabet"
            group="dict"
            active-class="v-list-item--active"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-title @click="dictionnaires = !dictionnaires">
                Dictionnaires
              </v-list-item-title>
            </template>
            <v-list-item link :to="{name: 'CategoryList'}">
              <v-list-item-action>
                <v-icon>mdi-file-tree</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Catégories</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              :to="{name: 'copieurMarque'}"
            >
              <v-list-item-action>
                <v-icon>mdi-watermark</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Marques</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              :to="{name: 'PrinterList'}"
              active-class="v-list-item--active"
            >
              <v-list-item-action>
                <v-icon>mdi-package</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Produits</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              :to="{name: 'LeaserList'}"
            >
              <v-list-item-action>
                <v-icon>mdi-bank</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Financeurs</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item link :to="{name: 'UserList'}">
            <v-list-item-action>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Utilisateurs</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar
        app
        clipped-left
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-toolbar-title>Capital bureautique</v-toolbar-title>
        <v-spacer />
        <router-link v-if="!$empty(user)" class="mr-3" :to="{ name: 'user' }">
          {{ user.name }}
        </router-link>
        <a class="mr-3" @click="logout">
          <v-icon>mdi-logout</v-icon>
          Deconnexion</a>
        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              elevation="0"
              v-on="on"
            >
              <v-icon dark>
                mdi-cogs
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item dense>
              <v-switch v-model="$parent.dark" flat label="Mode sombre" />
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-content>
        <v-container>
          <router-view />
        </v-container>
      </v-content>
    </v-app>
    <v-app v-else>
      <v-overlay opacity="1">
        <v-progress-circular color="primary" indeterminate size="64" />
      </v-overlay>
    </v-app>
    <modals-container />
  </div>
</template>

<script>
import {
  mapMutations, mapState,
} from 'vuex';

export default {
  name: 'App',
  data: () => ({
    drawer: null,
    source: 'test',
    darkTheme: true,
    proposition: false,
    dictionnaires: false,
  }),
  computed: {
    ...mapState(['user']),

    rightDrawer: {
      get () {
        return this.$store.state.drawer;
      },
      set (value) {
        this.setDrawer(value);
      },
    },
  },
  mounted () {
    this.fetchUser();
  },
  methods: {
    ...mapMutations(['setUser', 'setDrawer']),
    logout () {
      this.$http.post('/logout').then(() => {
        window.location.replace('/');
      });
    },
    fetchUser () {
      this.$http.get('/user').then(({ data }) => {
        this.setUser(data);
      });
    },
  },
};
</script>

<style>

</style>
