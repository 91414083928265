<template>
  <div>
    <v-row>
      <v-col>
        <h2>Liste des produits</h2>
      </v-col>
      <v-col cols="auto" class="mr-2">
        <v-btn color="primary" @click="create">
          <v-icon class="mr-1">
            mdi-plus-circle
          </v-icon>
          Ajouter
        </v-btn>
      </v-col>
    </v-row>
    <data-table
      ref="vue-table"
      api-url="/products"
      :fields="columns"
      :show-sort-icons="true"
      :sort-order="sortOrder"
    >
      <div slot="actions" slot-scope="props">
        <div class="custom-actions">
          <v-btn
            color="primary"
            :to="{ name: 'PrinterCard', params: {id: props.rowData.id, data: props.rowData} }"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-btn color="danger" @click.stop="doDelete(props.rowData)">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </div>
      </div>
    </data-table>
    <v-navigation-drawer
      v-model="drawer"
      width="400px"
      right
      temporary
      absolute
      class="pa-3"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <ValidationProvider
            v-slot="{ errors }"
            name="modèle"
            vid="editProduct.modele"
            rules="required"
          >
            <v-text-field
              v-model="product.modele"
              :error-messages="errors"
              label="Modèle"
              required
            />
          </ValidationProvider>
          <v-select
            v-model="product.marque_id"
            :items="marques"
            item-value="id"
            item-text="title"
            label="Marque"
          />
          <v-select
            v-model="product.category_id"
            :items="categories"
            item-text="title"
            item-value="id"
            label="Catégorie"
          />
          <div class="float-right">
            <v-btn :disabled="invalid" class="primary" @click="submit">
              Enregistrer
            </v-btn>
          </div>
        </form>
      </validation-observer>
    </v-navigation-drawer>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="320">
        <v-card>
          <v-card-title>
            Suppression du produit
          </v-card-title>
          <v-card-text>Etes vous sur de supprimer ce produit ?</v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="darken-1" text @click="dialog = false">
              non
            </v-btn>
            <v-btn color="red darken-1" text @click="confirmDelete">
              oui
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import DataTable from '../../components/datatable/table.vue';
import {
  mapActions, mapState,
} from 'vuex';

export default {
  name: 'List',
  components: { DataTable },
  data () {
    return {
      columns: [
        {
          name: 'modele',
          title: 'Modèle',
          sortField: 'modele',
        },
        {
          name: 'marque',
          title: 'Marque',
          callback: 'key|title',
        },
        {
          name: 'price',
          title: 'Prix',
          sortField: 'price',
          callback: 'price',
        },
        {
          name: '__slot:actions',
          title: '',
          titleClass: 'col-2 center aligned',
          dataClass: 'center aligned',
        },
      ],

      sortOrder: [
        {
          field: 'modele',
          direction: 'asc',
        },
      ],
      drawer: false,
      product: {
        modele: '', marque_id: 0,
      },
      dialog: false,
      toDelete: null,
    };
  },
  computed: {
    ...mapState({
      marques: state => state.dict.marques,
      categories: state => state.dict.categories,
    }),
  },
  mounted () {
    this.getCategories(this);
    this.getMarques(this);
  },
  methods: {
    ...mapActions('dict', ['getMarques', 'getCategories']),
    submit () {
      this.$http.post('/products', this.product).then(() => {
        this.$events.$emit('filter-set', this.product.modele);
        this.drawer = false;
      }).catch(err => console.error(err));
    },
    create () {
      this.drawer = true;
    },
    doDelete (product) {
      this.dialog = true;
      this.toDelete = product;
    },
    confirmDelete () {
      if (this.$empty(this.toDelete)) {
        this.dialog = false;
        return;
      }
      this.$http.delete(`/products/${this.toDelete.id}`).then(() => {
        this.dialog = false;
        this.$events.$emit('refresh');
      }).catch(err => console.error(err));
    },
  },
};
</script>

<style>

</style>
