import Vue from 'vue';
import Vuex from 'vuex';
import dict from './dict';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    drawer: false,
    drawerObject: null,
    civilities: [
      {
        title: 'Monsieur',
        value: 'MR',
      },
      {
        title: 'Madame',
        value: 'ME',
      },
      {
        title: 'Mademoiselle',
        value: 'MLE',
      },
    ],
  },
  mutations: {
    setUser (state, user) {
      state.user = user;
    },
    openDrawer (state, drawerObject) {
      state.drawer = true;
      state.drawerObject = drawerObject;
    },
    closeDrawer (state) {
      state.drawer = false;
      state.drawerObject = null;
    },
    setDrawerObject (state, obj) {
      state.drawerObject = obj;
    },
    setDrawer (state, drawer) {
      state.drawer = drawer;
      if (!drawer) {
        state.drawerObject = null;
      }
    },
  },
  modules: { dict },
});
