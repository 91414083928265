<template>
  <div class="filter-bar">
    <v-toolbar flat max-width="50%" class="mb-3">
      <v-text-field
        v-model="filterText"
        label="Recherche"
        class="mr-3"
        clearable
        @keypress.enter="doFilter"
      />
      <v-btn class="primary" @click.prevent="doFilter">
        Ok
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  data () {
    return { filterText: '' };
  },
  methods: {
    doFilter () {
      this.$events.$emit('filter-set', this.filterText);
    },
    resetFilter () {
      this.filterText = '';
      this.$events.$emit('filter-reset');
    },
  },
};
</script>
