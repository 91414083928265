import sanitize from './sanitize-html';
import { isEmpty } from '../../../core/functions';
import Safe from "./Safe";

export default {
  install: (Vue) => {
    Vue.prototype.$safe = (value) =>  isEmpty(value) ? '' : sanitize(value);
    Vue.filter('safe', (value) =>  isEmpty(value) ? '' : sanitize(value));
    Vue.filter('br', /** @type {string}*/ ( value) => isEmpty(value) ? '' : value.replace(/([\r\n])/g, '<br>'))
    Vue.component('safe', Safe);
  },
};
