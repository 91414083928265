<template>
    <div>
      <h1>Dashboard</h1>
    </div>
</template>

<script>
    export default {
        name: "Home"
    }
</script>

<style >

</style>
