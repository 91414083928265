<template>
    <pre class="text--primary">{{ JSON.stringify(object, null, 2) }}</pre>
</template>

<script>
  export default {
    name: "debug",
    props: {object: {type: Object, default: null, required: false}}
  }
</script>

<style>

</style>
