<template>
  <div>
    <v-row>
      <v-col>
        <h2>Liste des categories</h2>
      </v-col>
      <v-col cols="auto">
        <v-btn color="primary" @click="create">
          <v-icon class="mr-1">
            mdi-plus-circle
          </v-icon>
          Ajouter
        </v-btn>
      </v-col>
    </v-row>
    <data-table
      ref="vue-table"
      api-url="/categories"
      :fields="columns"
      :show-sort-icons="true"
    >
      <div slot="actions" slot-scope="props">
        <div class="custom-actions">
          <v-btn
            color="primary"
            @click="view(props.rowData)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn color="danger" @click.stop="doDelete(props.rowData)">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </div>
      </div>
    </data-table>
    <v-navigation-drawer
      v-model="rightDrawer"
      width="400px"
      right
      temporary
      absolute
    >
      <category :category="current" @update="updateCategory" />
    </v-navigation-drawer>
        <v-row justify="center">
      <v-dialog v-model="dialog" max-width="320">
        <v-card>
          <v-card-title>
            Suppression de la catégorie
          </v-card-title>
          <v-card-text>Etes vous sur de supprimer cette catégorie ?</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="darken-1" text @click="dialog = false">
              non
            </v-btn>
            <v-btn color="red darken-1" text @click="confirmDelete">
              oui
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import DataTable from '../../components/datatable/table.vue';
import Category from '../Drawers/Category.vue';
import {
  mapMutations,
} from 'vuex';

export default {
  name: 'List',
  components: {
    DataTable,
    Category,
  },
  data () {
    return {

      columns: [
        {
          name: 'title',
          title: 'Nom',
          sortField: 'title',
        },
        {
          name: '__slot:actions',
          title: '',
          titleClass: 'col-2 center aligned',
          dataClass: 'center aligned',
        },
      ],
      rightDrawer: false,
      current: null,
      dialog: false
    };
  },
  methods: {
    ...mapMutations(['openDrawer', 'closeDrawer']),
    view (data) {
      this.current = { ...data };
      this.rightDrawer = true;
    },
    create () {
      this.current = { title: '' };
      this.rightDrawer = true;
    },
    updateCategory () {
      this.rightDrawer = false;
      this.current = {};
      this.$events.$emit('refresh');
    },
    doDelete (category) {
      this.dialog = true;
      this.toDelete = category;
    },
    confirmDelete () {
      if (this.$empty(this.toDelete)) {
        this.dialog = false;
        return;
      }
      this.$http.delete(`/categories/${this.toDelete.id}`).then(() => {
        this.dialog = false;
        this.$events.$emit('refresh');
      }).catch(err => console.error(err));
    },
  },
};
</script>

<style >

</style>
