<template>
  <div class="pa-4" v-if="!$empty(category)">
    <v-text-field v-model="category.title" label="Nom" @keypress.enter="update"/>
    <v-btn v-if="!$empty(category.id)" class="float-right" color="primary" @click="update">Enregistrer</v-btn>
    <v-btn v-else color="primary" class="float-right" @click="create">Créer</v-btn>
  </div>
</template>

<script>
  export default {
    name: "Category",
    props: {
      category: {
        type: Object,
        default: () => ({})
      }
    },
    methods: {
      update() {
        this.$http.put(`/categories/${this.category.id}`, {title: this.category.title}).then(() => {
          this.$emit('update');
        }).catch(err => console.error(err));
      },
      create() {
        this.$http.post(`/categories`, {title: this.category.title}).then(() => {
          this.$emit('update');
        }).catch(err => console.error(err));
      }
    }
  }
</script>

<style >

</style>
