<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default { name: 'Router' };
</script>

<style>

</style>
