<template>
  <div>
    <div v-if="!!client && !$empty(client)">
      <v-card v-if="edit" class="pa-3">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form>
            <ValidationProvider
              v-slot="{ errors }"
              name="modèle"
              vid="editProduct.modele"
              rules="required"
            >
              <v-text-field
                v-model="editClient.name"
                :error-messages="errors"
                label="Modèle"
                required
              />
            </ValidationProvider>
            <v-text-field v-model="editClient.address" label="Adresse" />
            <v-text-field v-model="editClient.cp" label="Code postal" />
            <v-text-field v-model="editClient.ville" label="Ville" />
            <v-row>
              <v-col>
                <v-select
                  v-model="editClient.civility"
                  :error-messages="errors"
                  label="Civilité"
                  :items="civilities"
                  item-text="title"
                  item-value="value"
                />
              </v-col>
              <v-col>
                <v-text-field v-model="editClient.lastname" label="Nom" />
              </v-col>
              <v-col>
                <v-text-field v-model="editClient.firstname" label="Prénom" />
              </v-col>
            </v-row>
            <v-text-field v-model="editClient.email" label="Email" />
            <v-text-field v-model="editClient.tel" label="Tél" />

            <div class="float-right mt-4">
              <v-btn class="orange" @click="edit = false">
                Annuler
              </v-btn>
              <v-btn :disabled="invalid" class="primary" @click="submit">
                Enregistrer
              </v-btn>
            </div>
          </form>
        </validation-observer>
      </v-card>
      <v-card v-else>
        <v-row>
          <v-col>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  {{ client.name }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ client.address }} {{ client.cp }} {{ client.ville }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="hasContact">
                  Contact: {{ client.contact }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="client.email">
                  Mail: {{ client.email }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="client.tel">
                  Tel: {{ client.tel }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="auto" class="mr-2">
            <v-btn color="primary" @click="startEdit">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-if="!edit">
        <v-row>
          <v-col cols="12" lg="2" md="12">
            <v-tabs
              v-model="tab"
              :vertical="isVertical"

              class="mt-5"
            >
              <v-tab>
                Propositions
              </v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="12" md="12" lg="10">
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card flat>
                  <v-card-text>Proposition</v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div v-else>
      <v-overlay>
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </div>
  </div>
</template>

<script>
import DataTable from '../../components/datatable/table.vue';
import {
  mapState,
} from 'vuex';
import {
  isEmpty,
} from '../../../core/functions';

export default {
  name: 'Card',
  components: { DataTable },
  props: {
    id: {
      type: Number,
      required: true,
    },
    row: {
      default: () => ({}),
      type: Object,
    },
  },
  data () {
    return {
      client: null,
      tab: null,
      editClient: {},
      contact:
        {
          firstname: '',
          lastname: '',
          titre: '',
        },
      columns: [
        {
          title: 'Nom',
          name: 'fullname',
          sortField: 'firstname',
        },
        {
          title: 'Téléphone',
          name: 'phone',
        }, {
          title: 'Mobile',
          name: 'mobile',
        }, {
          title: 'Mail',
          name: 'mail',
        },
        {
          name: '__slot:actions',
          title: '',
          titleClass: 'col-3 center aligned',
          dataClass: 'center aligned',
        },
      ],
      edit: false,
      drawer: false,
      contactTab: 0,
      contactDialog: false,
      toLink: null,
      searchSoc: null,
      contacts: [],
      loading: false,
      timeout: null,
    };
  },
  computed: {
    ...mapState(['civilities']),
    isVertical () {
      return ['xs', 'sm', 'md'].indexOf(this.$vuetify.breakpoint.name) < 0;
    },
    hasContact () {
      return this.client && (this.client.firstname || this.client.lastname);
    },
  },
  watch: {
    searchSoc (newVal) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      if (isEmpty(newVal)) {
        this.contacts = [];
        return;
      }
      this.timeout = setTimeout(() => {
        this.$http.get('/contacts/search', { params: { filter: newVal } }).then(({ data }) => {
          this.contacts = data;
        }).catch(err => console.error(err));
      }, 200);
    },
  },
  mounted () {
    if (!this.$empty(this.row)) {
      this.client = this.row;
    } else {
      this.fetch();
    }
  },
  methods: {
    fetch () {
      this.$http.get(`/societes/${this.id}`).then(({ data }) => {
        this.client = data;
      }).catch(err => console.error(err));
    },
    startEdit () {
      const {
        name, address, cp, ville, email, firstname, lastname, tel, civility,
      } = this.client;
      this.editClient = {
        name, address, cp, ville, email, firstname, lastname, tel, civility,
      };
      this.edit = true;
    },
    submit () {
      this.$http.put(`/societes/${this.id}`, this.editClient).then(({ data }) => {
        this.client = data;
        this.edit = false;
      }).catch(err => console.error(err));
    },
    unlink (contact) {
      this.$http.delete(`/societes/${this.id}/contact/${contact.id}`).then(({ data }) => {
        this.$events.$emit('refresh');
      }).catch(err => console.error(err));
    },
    doDeleteContact (contact) {
      this.contactDialog = true;
      this.toDelete = contact;
    },
    confirmDelete () {
      if (this.$empty(this.toDelete)) {
        this.dialog = false;
        return;
      }
      this.$http.delete(`/contacts/${this.toDelete.id}`).then(() => {
        this.contactDialog = false;
        this.$events.$emit('refresh');
      }).catch(err => console.error(err));
    },
    submitContact () {
      this.$http.post(`/societes/${this.id}/contact`, this.contact).then(() => {
        this.drawer = false;
        this.contact = {
          firstname: '',
          lastname: '',
          titre: '',
        };
        this.$refs.observer.reset();
        this.$events.$emit('refresh');
      }).catch(err => console.error(err));
    },
    link () {
      this.$http.post(`/societes/${this.id}/contact/${this.toLink}`).then(() => {
        this.drawer = false;
        this.searchSoc = '';
        this.toLink = null;
        this.$events.$emit('refresh');
      }).catch(err => console.error(err));
    },
  },
};
</script>

<style>

</style>
