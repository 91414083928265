<template>
  <div>
    <v-row>
      <v-col>
        <h2>Liste des Utilisateur</h2>
      </v-col>
      <v-col cols="auto">
        <v-btn color="primary" @click="create">
          <v-icon class="mr-1">
            mdi-plus-circle
          </v-icon>
          Ajouter
        </v-btn>
        <v-btn @click="showTrash">
          <v-icon class="mr-2">
            mdi-trash-can
          </v-icon>
          {{ trashedLabel }}
        </v-btn>
      </v-col>
    </v-row>
    <data-table
      ref="vue-table"
      api-url="/users"
      :fields="columns"
      :show-sort-icons="true"
      :sort-order="sortOrder"
      :append-params="{ trash }"
    >
      <div slot="actions" slot-scope="props">
        <div class="custom-actions">
          <v-btn
            v-if="!props.rowData.deleted_at"
            color="primary"
            @click="view(props.rowData)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn v-if="props.rowData.deleted_at" color="warning" @click="restore(props.rowData)">
            <v-icon>mdi-delete-restore</v-icon>
          </v-btn>
          <v-btn v-if="props.rowData.id !== storeUser.id" color="danger" @click.stop="doDelete(props.rowData)">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </div>
      </div>
    </data-table>
    <v-navigation-drawer
      v-model="drawerEdit"
      width="400px"
      right
      temporary
      absolute
      class="pa-3"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <ValidationProvider v-slot="{ errors }" name="Nom" rules="required">
            <v-text-field
              v-model="user.name"
              :error-messages="errors"
              label="Nom"
              required
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="Email" rules="required">
            <v-text-field
              v-model="user.email"
              :error-messages="errors"
              label="Email"
              required
            />
          </ValidationProvider>
          <v-text-field
            v-model="user.poste"
            label="Poste"
          />
          <v-text-field
            v-model="user.phone"
            label="Téléphone"
          />
          <v-checkbox v-if="user.id !== storeUser.id" v-model="user.admin" label="Admin" />
          <div class="float-right">
            <v-btn :disabled="invalid" class="primary" @click="update">
              Enregistrer
            </v-btn>
            <v-btn class="warning" @click="reset">
              Réinitialisation mot de passe
            </v-btn>
          </div>
        </form>
      </validation-observer>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="drawerCreate"
      width="400px"
      right
      temporary
      absolute
      class="pa-3"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <ValidationProvider v-slot="{ errors }" name="Nom" rules="required">
            <v-text-field
              v-model="user.email"
              :error-messages="errors"
              label="Email"
              required
            />
          </ValidationProvider>
          <div class="float-right">
            <v-btn :disabled="invalid" class="primary" @click="submit">
              Ajouter
            </v-btn>
          </div>
        </form>
      </validation-observer>
    </v-navigation-drawer>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="320">
        <v-card>
          <v-card-title>
            Suppression de l'utilisateur
          </v-card-title>
          <v-card-text>Êtes vous sur de supprimer cet utilisateur ?</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="darken-1" text @click="dialog = false">
              non
            </v-btn>
            <v-btn color="red darken-1" text @click="confirmDelete">
              oui
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import DataTable from '../../components/datatable/table.vue';
import {
  mapState,
} from 'vuex';

export default {
  name: 'ListUser',
  components: { DataTable },

  data () {
    return {
      trash: false,
      columns: [
        {
          name: 'admin',
          title: '',
          sortField: 'admin',
          callback: 'userAdmin',
        },
        {
          name: 'name',
          title: 'Nom',
          sortField: 'name',
        },
        {
          name: 'email',
          title: 'Email',
          sortField: 'email',
        },
        {
          name: 'poste',
          title: 'Poste',
          sortField: 'poste',
        },
        {
          name: 'phone',
          title: 'Téléphone',
          sortField: 'phone',
        },
        {
          name: '__slot:actions',
          title: '',
          titleClass: ' col-2 center aligned',
          dataClass: 'center aligned',
        },
      ],
      sortOrder: [
        {
          field: 'name',
          direction: 'asc',
        },
      ],
      user: null,
      dialog: false,
      drawerCreate: false,
      drawerEdit: false,
    };
  },
  computed: {
    ...mapState({ storeUser: 'user' }),
    trashedLabel () {
      if (this.trash) {
        return 'Sortie de la corbeille';
      }
      return 'Corbeille';
    },
  },
  methods: {
    doDelete (user) {
      this.user = user;
      this.dialog = true;
    },
    view (user) {
      this.user = { ...user };
      this.drawerEdit = true;
    },
    showTrash () {
      this.trash = !this.trash;
      this.$events.$emit('filter-set', '');
    },
    create () {
      this.user = {};
      this.drawerCreate = true;
    },
    confirmDelete () {
      this.$http.delete(`/users/${this.user.id}`).then(() => {
        this.dialog = false;
        this.$events.$emit('refresh');
      }).catch(() => {
        this.dialog = false;
      });
    },
    restore (user) {
      this.$http.post(`/users/${user.id}/restore`).then(() => {
        this.$events.$emit('refresh');
      }).catch(() => {
        this.dialog = false;
      });
    },
    reset () {
      this.$http.post(`/users/${this.user.id}/reset`).then(() => {
        this.$events.$emit('refresh');
      }).catch(() => {
        this.dialog = false;
      });
    },
    update () {
      this.$http.put(`/users/${this.user.id}`, {
        name: this.user.name,
        email: this.user.email,
        admin: this.user.admin,
        poste: this.user.poste,
        phone: this.user.phone,
      }).then(() => {
        this.drawerEdit = false;
        this.$events.$emit('refresh');
      }).catch((err) => console.error(err));
    },
    submit () {
      this.$http.post('users', { email: this.user.email }).then(() => {
        this.drawerCreate = false;
        this.user = {};
        this.$events.$emit('refresh');
      });
    },
  },
};
</script>
