<template>
  <div>
    <div v-if="!$empty(contact)">
      <v-card v-if="edit" class="pa-3">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form>
            <v-row>
              <v-col lg="4" sm="12" class="col-xs-12">
                <ValidationProvider v-slot="{ errors }" name="Civilité" rules="required">
                  <v-select
                    v-model="editContact.titre"
                    :error-messages="errors"
                    label="Civilité"
                    :items="civilities"
                    item-text="title"
                    item-value="value"
                  />
                </ValidationProvider>
              </v-col>
              <v-col lg="4" sm="12" class="col-xs-12">
                <ValidationProvider v-slot="{ errors }" name="Nom" rules="required">
                  <v-text-field
                    v-model="editContact.firstname"
                    :error-messages="errors"
                    label="Nom"
                    required
                  />
                </ValidationProvider>
              </v-col>
              <v-col lg="4" sm="12" class="col-xs-12">
                <ValidationProvider v-slot="{ errors }" name="Prénom" rules="required">
                  <v-text-field
                    v-model="editContact.lastname"
                    :error-messages="errors"
                    label="Prénom"
                    required
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="4" sm="12" class="col-xs-12">
                <v-text-field v-model="editContact.mail" label="Email" />
              </v-col>
              <v-col lg="4" sm="12" class="col-xs-12">
                <v-text-field v-model="editContact.phone" label="Téléphone" />
              </v-col>
              <v-col lg="4" sm="12" class="col-xs-12">
                <v-text-field v-model="editContact.mobile" label="Portable" />
              </v-col>
            </v-row>
            <div class="float-right mt-5">
              <v-btn :disabled="invalid" class="primary" @click="submit">
                Enregistrer
              </v-btn>
            </div>
          </form>
        </validation-observer>
      </v-card>
      <v-card v-else>
        <v-row>
          <v-col>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                  {{ contact.civility }} {{ contact.lastname }} {{ contact.firstname }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ contact.address }} {{ contact.cp }} {{ contact.ville }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-text>
              <div v-if="!$empty(contact.phone)">
                <div class="subtitle-2">
                  Téléphone
                </div>
                <div class="caption">
                  {{ contact.phone }}
                </div>
              </div>
              <div v-if="!$empty(contact.mobile)">
                <div class="subtitle-2">
                  Mobile
                </div>
                <div class="caption">
                  {{ contact.mobile }}
                </div>
              </div>
              <div v-if="!$empty(contact.mail)">
                <div class="subtitle-2">
                  Email
                </div>
                <div class="caption">
                  <a :href="`mailto:${contact.mail}`">{{ contact.mail }}</a>
                </div>
              </div>
            </v-card-text>
          </v-col>
          <v-col cols="auto" class="mr-2">
            <v-btn color="primary" @click="startEdit">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-if="!edit" class="mt-2">
        <v-row>
          <v-col>
            <v-card-title>Societes</v-card-title>
          </v-col>
          <v-col cols="auto" class="mr-2">
            <v-autocomplete
              v-model="toLink"
              :search-input.sync="searchSoc"
              :items="societes"
              label="Liaison sociétés"
              :loading="loading"
              item-text="name"
              item-value="id"
            />
            <v-btn v-if="!$empty(toLink)" color="primary" @click="link">
              Lier
            </v-btn>
          </v-col>
        </v-row>
        <v-simple-table class="pa-3">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Nom
                </th>
                <th class="text-left">
                  Adresse
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr v-for="societe in contact.societes" :key="societe.id">
                <td>{{ societe.name }}</td>
                <td>{{ societe.address }} {{ societe.cp }} {{ societe.ville }}</td>
                <td>
                  <v-btn
                    color="primary"
                    :to="{ name: 'ClientCard', params: { id: societe.id, data: societe } }"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn color="danger" @click.stop="unlink(societe)">
                    <v-icon>mdi-link-off</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
    <div v-else>
      <v-overlay>
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </div>
  </div>
</template>

<script>
import {
  mapState,
} from 'vuex';
import {
  isEmpty,
} from '../../../core/functions';

export default {
  name: 'Card',
  props: {
    id: {
      type: Number,
      required: true,
    },
    row: {
      default: () => ({}),
      type: Object,
    },
  },
  data: function () {
    return {
      contact: null,
      editContact: {},
      edit: false,
      dialog: false,
      dialog2: false,
      toLink: null,
      societes: [],
      searchSoc: null,
      socIds: [],
      loading: false,
      timeout: null,
    };
  },
  computed: { ...mapState(['civilities']) },
  watch: {
    searchSoc (newVal) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      if (isEmpty(newVal)) {
        this.societes = [];
        return;
      }
      this.timeout = setTimeout(() => {
        this.$http.get('/societes/search', { params: { name: newVal } }).then(({ data }) => {
          this.societes = data.filter(soc => this.socIds.indexOf(soc.id) < 0);
        }).catch(err => console.error(err));
      }, 200);
    },
  },
  mounted () {
    if (!this.$empty(this.row)) {
      this.contact = this.row;
    }
    this.fetch();
  },
  methods: {
    startEdit () {
      this.edit = true;
      this.editContact = { ...this.contact };
    },
    fetch () {
      this.$http.get(`/contacts/${this.id}`).then(({ data }) => {
        this.contact = data;
        this.socIds = data.societes.map(soc => soc.id);
      }).catch(err => console.error(err));
    },
    submit () {
      this.$http.put(`/contacts/${this.id}`, this.editContact).then(({ data }) => {
        this.contact = data;
        this.edit = false;
      }).catch(err => console.error(err));
    },
    link () {
      this.$http.post(`/societes/${this.toLink}/contact/${this.id}`).then(() => {
        this.searchSoc = '';
        this.fetch();
      }).catch(err => console.error(err));
    },
    unlink (societe) {
      this.$http.delete(`/societes/${societe.id}/contact/${this.id}`).then(() => {
        this.fetch();
      }).catch(err => console.error(err));
    },
  },
};
</script>

<style>

</style>
